import React from "react";
import styled, { css } from "styled-components";

export default ({ inverse, textAlign, title, lead }) => (
  <Header>
    <Heading textAlign={textAlign} inverse={inverse}>
      {title}
    </Heading>
    {lead && (
      <Lead textAlign={textAlign} inverse={inverse}>
        {lead}
      </Lead>
    )}
  </Header>
);

const Header = styled.header`
  margin: 0 0 32px;
`;

const Heading = styled.h2`
  font-size: 24px;
  color: ${({ theme, inverse }) =>
    inverse ? theme.colors.normalOnMain : theme.colors.normal};
  margin: 0 0 16px;

  ${({ textAlign }) =>
    textAlign
      ? css`
          text-align: ${textAlign};
        `
      : css`
          display: flex;
          align-items: center;

          &::before {
            content: "";
            display: block;
            width: 5px;
            height: 40px;
            background-color: ${({ theme, inverse }) =>
              inverse ? theme.colors.normalOnMain : theme.colors.main};
            margin-right: 16px;
            border-radius: 99px;
          }
        `};
`;

const Lead = styled.p`
  color: ${({ theme, inverse }) =>
    inverse ? theme.colors.mutedOnMain : theme.colors.muted};
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
  margin: 0;
`;

import React from "react";
import styled, { css } from "styled-components";
import Container from "../components/Container";
import SEO from "../components/SEO";
import PageHeader from "../components/PageHeader";
import SectionHeader from "../components/SectionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  faAppStoreIos,
  faGooglePlay,
} from "@fortawesome/free-brands-svg-icons";
import Row from "../components/Row";
import Column from "../components/Column";
import LinkButton from "../components/LinkButton";
import CareersBanner from "../components/CareersBanner";
import Image from "../components/Image";
import { max_md } from "../utils/mediaQuery";

export default () => (
  <>
    <SEO
      description="株式会社SocialDogが提供するSocialDogを紹介します。"
      title="サービス"
    />
    <PageHeader title="Services" lead="サービス" />
    <Container>
      <ServiceSection>
        <SectionHeader
          title={
            <>
              <ServiceName>SocialDog</ServiceName>
              <DeviceIconLink
                href="https://social-dog.net/"
                title="SocialDogのウェブサイト"
              >
                <DeviceIcon icon={faGlobe} />
              </DeviceIconLink>
              <DeviceIconLink
                href="https://itunes.apple.com/us/app/id1450787857?l=ja&ls=1&mt=8"
                title="SocialDog iOSアプリ"
              >
                <DeviceIcon icon={faAppStoreIos} />
              </DeviceIconLink>
              <DeviceIconLink
                href="https://play.google.com/store/apps/details?id=jp.autoscale.socialdog&hl=ja"
                title="SocialDog Androidアプリ"
              >
                <DeviceIcon icon={faGooglePlay} />
              </DeviceIconLink>
            </>
          }
        />
        <Row>
          <Column>
            <CardSocialDog>
              <ServiceLogo
                filename="socialdog_logo.png"
                alt="SocialDog"
                width="700"
                height="163"
              />
              <ServiceScreens
                filename="socialdog_tiles.png"
                alt=""
                width="787"
                height="568"
              />
            </CardSocialDog>
          </Column>
          <Column>
            <Lead>
              SNS運用担当者のための
              <br />
              マーケティングツール
            </Lead>
            <DescriptionContent>
              <DescriptionParagraph>
                「SocialDog」は、100万アカウント以上に利用されている、SNS管理ツールです。X（Twitter）・Instagram・Facebookの3つのSNSに対応しています。
              </DescriptionParagraph>
              <DescriptionParagraph>
                予約投稿やフォロー管理といった運用効率化ツール、フォロワー数やエンゲージメント率の分析ツールなどを通じて、SNSアカウント運用を強力にサポートします。
              </DescriptionParagraph>
              <DescriptionParagraph>
                スタートアップから大企業まで、個人や企業など、あらゆる業種、規模のみなさまにSNSアカウントをスマートで効率的に運用できるツールを提供します。
              </DescriptionParagraph>
            </DescriptionContent>
            <LinkButton
              as="a"
              href="https://social-dog.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              サービスをみる
            </LinkButton>
          </Column>
        </Row>
      </ServiceSection>
    </Container>
    <CareersBanner />
  </>
);

const ServiceSection = styled.section`
  margin: 0 0 80px;
`;

const CardStyle = css`
  border-radius: 24px;
  padding: 40px;
  height: 400px;
  overflow: hidden;
  margin: 0 0 30px;

  ${max_md(css`
    height: 320px;
  `)}
`;

const CardSocialDog = styled.div`
  ${CardStyle}
  background: ${({ theme }) => theme.colors.mainLight};
`;

const ServiceName = styled.span`
  display: inline-block;
  padding: 0 8px 0 0;
`;

const DeviceIconLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  display: inline-block;
  padding: 5px;
  opacity: 0.75;
`;

const DeviceIcon = styled(FontAwesomeIcon).attrs({
  fixedWidth: true,
})``;

const ServiceLogo = styled(Image)`
  display: block;
  width: 120px;
  height: auto;
  margin: 0 auto 24px;
`;

const ServiceScreens = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
`;

const Lead = styled.h3`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 24px;

  ${max_md(css`
    font-size: 20px;
    margin: 0 0 16px;
  `)}
`;

const DescriptionContent = styled.div`
  margin: 0 0 24px;
`;

const DescriptionParagraph = styled.p`
  margin: 0;
`;
